import React, { Component, ChangeEvent, FormEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Styles
import './styles/App.css';
import './styles/Projects.css';

import { getMe, getStories } from './api';
import { Story } from './StoryInterface';

interface PivotalProject {
  project_id: number;
  project_name: string;
}

interface PivotalStory {
  name: string;
  estimate: number;
}

interface PivotalSetupProps {
  setStories: (stories: Story[]) => void;
  handleInput: (event: ChangeEvent<HTMLInputElement>) => void;
  iterationLength: number;
}

type Props = PivotalSetupProps & RouteComponentProps;

class State {
  token: string | null = null;
  projects: PivotalProject[] = [] ;
  error: string | null = null;
  name: string | null = null;
  isLoading: boolean = false;
  stories: Story[] = [];
  selectedProject: string | null = null;
}

class PivotalSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = new State();
  }

  // Check to see whether there's a token in localStorage. If so, use it to
  // fetch projects.
  componentDidMount(): void {
    const token = window.localStorage.getItem('token');
    if (!token) return;
    this.fetchProjects(token);
  }

  // Set the token in state.
  handleChange = (event: ChangeEvent<HTMLInputElement>): void => {  
    this.setState({token: event.target.value, error: null})
  };

  // Fetch projects using token from form.
  handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { token } = this.state;
    if(!token) return;
    this.fetchProjects(token)
  }

  // Remove token from localStorage and state.
  clearToken = (): void => {
    window.localStorage.removeItem('token');
    this.setState({ token: null });
  };

  projectedStories = (stories: PivotalStory[]): Story[] => {
    const ourStories = stories.map((story) => {
      return { title: story.name, points: story.estimate }
    })
    
    return ourStories.filter((story) => {
      return story.points > 0;
    })
  }

  // Fetch stories from PivotalTracker for a project.
  fetchStories = (event: any, id: number, name: string) => {
    event.preventDefault();
    this.setState({ selectedProject: name, isLoading: true });
    getStories(id)
      .then((response: PivotalStory[]) => {
        this.setState({ stories: this.projectedStories(response) });
    });
  }

  // Fetch projects and person's name from PivotalTracker.
  fetchProjects = (token: string) => {  
    this.setState({ isLoading: true });
    getMe(token)
      .then((response: { name: string; projects: PivotalProject[] }) => {    
        const { name, projects } = response;
        window.localStorage.setItem('token', token);
        this.setState({ token, projects, name, isLoading: false, error: null });
      })
      .catch((error: any) => {
        this.clearToken();
        if (error.response && error.response.status === 403) {
          this.setState({
            ...this.state,
            isLoading: false,
            error: 'Invalid API token :(',
            projects: [],
            selectedProject: ''
          });
        }
      });
  };

  // Set stories in state and start the game!
  beginGame() {
    const { setStories, history } = this.props;
    setStories(this.state.stories);
    history.push('/play');
  }

  // Render project section.
  renderProjects(): JSX.Element {
    const { selectedProject, stories } = this.state;
    const { iterationLength, handleInput } = this.props;

    return (
      <>
        <section className='light'>
          <div className='projectContainer'>
            <div className='projects'>
              <h2>1. Choose your project:</h2>
              <ul>
                {this.state.projects.map(p => {
                  return (
                    <li key={p.project_id}>
                      <a href='#' onClick={(e) => this.fetchStories(e, p.project_id, p.project_name)}>
                        {p.project_name}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className={selectedProject ? 'stories' : 'stories hidden'}>
              <h2>2. Check your stories:</h2>
              { selectedProject && stories.length > 0 ?
                (
                  <>
                    <ul>
                      {this.state.stories.map(story => {
                        return (
                          <li className='story' key={story.title}>
                            <div className='text-bold'>[{story.points}&nbsp;point]</div>
                            <div>{' '}{story.title}</div>
                          </li> 
                        )
                      })}
                    </ul>
                    <p className='text-bold'>Total: {stories.length} stories</p>
                  </>
                ) :
                <div>
                  <h4>
                    We couldn't find any stories in{' '}
                    <span className='text-orange'>{selectedProject}</span>
                    {' '}with the label{' '}
                    <span className='text-orange'>'velocitaire'</span>
                  </h4>
                  <p>
                    Head to your project board for {selectedProject}, select which
                    stories you'd like estimate velocity for, and label them all
                    with 'velocitaire'.
                  </p>
                </div>
              }
            </div>
          </div>
        </section>
        { selectedProject && stories.length > 0 &&
          <section className='dark'>
            <div>
              <h3 className='small-subtitle'>3. Happy with those stories?</h3>
              <p>
                You're almost ready to play! Just tell us how many weeks one of
                your iterations is:
              </p>
              <label>
                Iteration length:
                <input
                  id='iteration-length'
                  type='number'
                  value={iterationLength}
                  onChange={handleInput}
                />
              </label>
              <div>
                <button 
                  type='submit'
                  className='button'
                  onClick={this.beginGame.bind(this)}
                  disabled={stories.length === 0 || !iterationLength}
                >
                  Play!
                </button>
              </div>
            </div>
          </section>
        }
      </>
    )
  }

  render(): JSX.Element {
    const { token, error, projects } = this.state;
    
    return (
      <>
        <section className='dark'>
          <h2 className='small-subtitle'>Connect your PivotalTracker project</h2>

          <p>
            You can find your personal API token on your PivotalTracker{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pivotaltracker.com/profile"
            >
              profile page
            </a>
            .
          </p>

          <form onSubmit={this.handleSubmit}>
            <label htmlFor='token'>API token:</label>
            <input type='text'
              value={token || ''}
              id={'token'}
              onChange={this.handleChange}
            />
            <button className='button' type='submit'>Submit</button>
            {error && <p>{error}</p>}
          </form>
        </section>
        
        {projects.length > 0 && this.renderProjects()}

        <section className='light'>
          <h3>Don't worry!</h3>
          <p>
            We use your token to fetch information from PivotalTracker and it
            is stored safely here in your browser.
          </p>
          <p>
            Pixie Labs does not store your token or any of your details from
            PivotalTracker.
          </p> 
        </section>
      </>
    );
  }
}

export default withRouter(PivotalSetup);
