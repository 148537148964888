import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';

import { Round } from './RoundClass';
import {
  calculateOverallVelocity,
  totalNumberOfWeeks,
  numberOfIterations
} from './lib/velocityHelpers'

interface Props {
  rounds: Round[],
  playAgain(finished: boolean): void,
  resetGame(): void,
  totalStoryPoints: number,
  iterationLength: number
}

const GameOver: FunctionComponent<Props> = ({
  rounds, playAgain, resetGame, totalStoryPoints, iterationLength
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (window.confirm('Are you sure you want to exit this game? You will lose your stories and game progress.')) {
      playAgain(true);
      history.push('/');
    }
  }

  return (
    <>
      <div className='indicators'>
        {rounds.map((round, index) => {
          return (
            <div key={index} className='indicator-game-over'>
              <div className='indicators-badge'>
                <div>{ round.averageVelocity }</div>
              </div>
            </div>
          )
        })}
      </div>

      <section className='dark gameover'>
        <h1 className='small-subtitle'>Your estimated sprint velocity is:</h1>
        <div className='estimated-velocity'>
          {calculateOverallVelocity(rounds)}
        </div>

        <p>This project is {totalStoryPoints} story points long.</p>

        <p>
          This will take you {numberOfIterations(totalStoryPoints, rounds)}
          {' '}iterations or{' '}
          {totalNumberOfWeeks(iterationLength, totalStoryPoints, rounds)} weeks.
        </p>

        <small className='text-orange'>Now hop to it.</small>

        <section>
          <button className='button' onClick={resetGame}>
            Play again
          </button>
          
          <button className='button' onClick={handleClick}>
            Upload new stories
          </button>
        </section>
      </section>
    </>
  )
}

export default GameOver;