import axios from 'axios';

interface OptionParams {
  token?: string;
  params?: {};
}

export const getMe = (token: string) => {
  return request(axios.get('me', options({ token })))
};

export const getStories = (id: number) => {
  const params = { with_label: 'velocitaire' }
  return request(axios.get(`projects/${id}/stories`, options({ params })));
}

const request = async (promise: Promise<any>) => {
  const response = await promise;
  return response.data;
}

const options = ({ token, params }: OptionParams = {}) => ({
  baseURL: 'https://www.pivotaltracker.com/services/v5/',
  headers: { 'X-TrackerToken': token || window.localStorage.getItem('token') },
  params
});