import { Story } from '../StoryInterface';
import { Round } from '../RoundClass';

export function calculateRoundVelocity(stories: Story[]) {
  const reducer = (accumulator: number, currentValue: Story) => {
    return accumulator + currentValue.points;
  }
  // Because currentValue is not of type `number`, we need to pass in an initial
  // value of 0
  return stories.reduce(reducer, 0)
}

export function calculateOverallVelocity(rounds: Round[]) {
  const reducer = (accumulator: number, currentValue: Round) => {
    return accumulator + currentValue.averageVelocity;
  }
  // Because currentValue is not of type `number`, we need to pass in an initial
  // value of 0
  return Math.ceil(rounds.reduce(reducer, 0)/rounds.length)
}

export function calculateTotalStoryPoints(stories: Story[]) {
  const reducer = (totalPoints: number, currentStory: Story) => {
    return totalPoints + Number(currentStory.points)
  };
  return stories.reduce(reducer, 0);
}

export function numberOfIterations(totalStoryPoints: number, rounds: Round[]) {
  return roundUpToHalf(totalStoryPoints/calculateOverallVelocity(rounds));
}

export function totalNumberOfWeeks(
  iterationLength: number, totalStoryPoints: number, rounds: Round[]
) {
  const iterations = numberOfIterations(totalStoryPoints, rounds)
  return roundUpToHalf(iterationLength * iterations);
}

function roundUpToHalf(num: number) {
  return Math.ceil(num*2)/2;
}
