export const track = (
  action: string,
  category?: string,
  label?: string,
  value?: string,
  // Is it a non-interactive event? (affects bounce rate calc)
  nonInteraction: boolean = false) => {
  if (gtag) {
    gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value,
      'non_interaction': nonInteraction
    });
  }
};