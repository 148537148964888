import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';

import { Story } from './StoryInterface';
import { Round } from './RoundClass';
import GameOver from './GameOver';

interface Props {
  iterationLength: number,
  rounds: Round[],
  acceptStory(): void,
  rejectStory(): void,
  currentStory: Story | null,
  acceptedStories: Story[],
  finishRound(): void,
  playAgain(finished: boolean): void,
  resetGame(): void,
  gameOver: boolean,
  totalStoryPoints: number
}

// In-game screen.
const GamePlay: FunctionComponent<Props> = ({
  iterationLength, rounds, acceptStory, rejectStory, currentStory,
  acceptedStories, finishRound, playAgain, gameOver, resetGame, totalStoryPoints
}) => {

  const history = useHistory();
  const handleClick = () => {
    if (window.confirm('Are you sure you want to exit this game? You will lose your stories and game progress.')) {
      playAgain(false);
      history.push('/');
    }
  }

  const numberOfWeeks = iterationLength === 1 ? '1 week' : `${iterationLength} weeks`;

  if (gameOver) {
    return (
      <GameOver
        rounds={rounds}
        playAgain={playAgain}
        resetGame={resetGame}
        totalStoryPoints={totalStoryPoints}
        iterationLength={iterationLength}
      />
    )
  }

  const renderRound = (index: number) => {
    return (
      <div key={index} className='indicators-badge lock'>
        Round {index + 1}
      </div>
    )
  }

  return (
    <>
      <div className='indicators'>
        { rounds.map((_, index) => renderRound(index)) }
        <div id='current-round' className='indicators-badge'>
          Round {rounds.length + 1}
        </div>
      </div>

      <div className='end-game-buttons'>
        { acceptedStories.length >= 1 &&
          <button id='finish-round' className='button' onClick={finishRound}>
            End this round
          </button>
        }
        <button id='restart-game' className='button' onClick={handleClick}>
          End whole game
        </button>
      </div>
      
      <section className='dark more-padding'>
        <h2>In {numberOfWeeks}, could you...</h2>

        <button className='button button--round button--cross' onClick={rejectStory}>
          x
        </button>
        <div className='card' id='current-story'>
          {currentStory && currentStory.title}
        </div>
        <button className='button button--round button--tick' onClick={acceptStory}>
          ✓
        </button>

        <div className='current-iteration'>
          <div id='accepted-stories'>
            {acceptedStories.map((story) =>
              <div className='card card--small' key={story.title}>
                {story.title}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default GamePlay;
