import XLSX from 'xlsx';
// https://developer.mozilla.org/en-US/docs/Web/API/FileReader

export default (file) => {
  return new Promise((resolve, reject) => {
   const reader = new FileReader();

    // FileReader.onError is executed if the reading operation encounters an
   // error.
   reader.onerror = () => {
     // Abort the read operation.
     reader.abort();
     // And reject the promise.
     reject(reader.error);
   }

    // FileReader.onload is executed when content read with readAsArrayBuffer
   // is available.
   reader.onload = (e) => {
     // Parse data from workbook.
     const result = e.target.result;
     const workBook = XLSX.read(result, { type: 'array' });

      // Get first worksheet.
     const workSheetName = workBook.SheetNames[0];
     const workSheet = workBook.Sheets[workSheetName];

      // Convert data in workSheet to array of arrays.
     // The header option means the first row is considered a data row.
     const data = XLSX.utils.sheet_to_json(workSheet, { header: 1, blankrows: false });

      // Return the data.
     resolve(data);
   }

    reader.readAsArrayBuffer(file);
 });
}
