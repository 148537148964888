import React, {Component, ChangeEvent } from 'react';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Story } from './StoryInterface';
import { track } from './lib/ga';
import readFile from './lib/fileReader';

interface SpreadsheetSetupProps {
  setStories: (stories: Story[]) => void;
  handleInput: (event: ChangeEvent<HTMLInputElement>) => void;
  availableStories: Story[];
  iterationLength: number;
}

type Props = SpreadsheetSetupProps & RouteComponentProps;

// So we can show when a speadsheet's been uploaded successfully.
class State {
  spreadsheet: string | null = null;
  error: string | null = null;
}

// Renders the Story Upload Page.
class SpreadsheetSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = new State();
  }

  // returns the extension from the file name
  getFileExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  }

  // Check that each row of the spreadsheet is formatted correctly.
  isInvalid(story: [string, number]): Boolean {
    return typeof story[0] != "string" || typeof story[1] != "number";
  }

  // Formats the inner arrays as story objects. `readFile` returns the stories
  // from the spreadsheet as an array of arrays
  formatStories(stories: [string, number][]): Story[] {
    return stories.map((story) => {
      return { title: story[0], points: story[1] }
    });
  }

  // Adds the imported stories to state, or handles error.
  async importStories(e: ChangeEvent<HTMLInputElement>) {
    this.setState({error: null});

    if (e.target.files && e.target.files.length){
      let file = e.target.files[0];
      let fileExtension = this.getFileExtension(file.name);

      // Check for file extension .xlsx
      if (fileExtension === 'xlsx'){
        try {
          // Read stories from spreadsheet.
          let allStories = await readFile(file);

          // Check that the data is valid
          if (allStories.some((story: [string, number]) => this.isInvalid(story))) {
            track('Uploaded spreadsheet', undefined, 'Invalid');
            this.setState({
              error: "There's something wrong with your spreadsheet format."
            });
          } else {
            track('Uploaded spreadsheet', undefined, 'Valid');
            // Format them as stories
            allStories = this.formatStories(allStories);
            // and save them to state + sessionStorage
            this.props.setStories(allStories);
          }
        } catch (e) {
          console.log(e);
          this.setState({ error: 'Error uploading stories' });
          track('Uploaded spreadsheet', undefined, 'Error');
        }
        this.setState({spreadsheet: file.name});
      } else {
        this.setState({ error:'File type .xlsx expected' });
      }
    }
  }

  anyStories() {
    return this.state.spreadsheet && this.props.availableStories.length > 0
  }

  render () {
    const { error } = this.state;
    const {
      availableStories, iterationLength, handleInput, history
    } = this.props;

    return (
      <>
        <section className='dark'>
          <h2 className='small-subtitle'>Upload a SpreadSheet</h2>
          <div>
            <p>
              You'll need an{' '}
              <span className='text-orange'>XLSX spreadsheet</span>
              {' '}containing your stories and their points.
            </p>
            <p>
              The spreadsheet should have no header row. The first column
              should be the stories and the second column should be the number of
              points.
            </p>
            <p>
              Download an{' '}
              <a href="/SampleProjectBacklog.xlsx">example spreadsheet</a>
              {' '}here.
            </p>
            <input
              id='file-upload'
              type="file"
              onChange={this.importStories.bind(this)}
            />
            <label htmlFor='file-upload' className='button'>
              Upload a spreadsheet
            </label>
            {error && <p>{error}</p>}
            {this.anyStories() && <p>Imported {availableStories.length} stories.</p>}
          </div>
        </section>
        
        {
          this.anyStories() && (
            <section className='light'>
              <h3 className='small-subtitle'>Happy with those stories?</h3>
              <p>
                You're almost ready to play! Just tell us how long one of your
                iterations is (e.g. 1 week):
              </p>
              <label>How many weeks is one of your iterations?
                <input
                  id='iteration-length'
                  type='number'
                  value={iterationLength}
                  onChange={handleInput}
                />
              </label>
              <br />
              <button
                id='play'
                className='button'
                onClick={() => history.push('/play')}
                disabled={availableStories.length === 0 || !iterationLength}
              >
                Play
              </button>
            </section>
          )
        }
      </>
    );
  }
}

export default withRouter(SpreadsheetSetup);