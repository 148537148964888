import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export type ButtonProps = {
  className: string,
  href: string,
  target: string,
};

const Button: FunctionComponent<ButtonProps> = ({className, href, target, children}) =>
  <div className="buttonWrapper">
    <a
      className={className ? `button ${className}` : 'button'}
      href={href}
      target={target}>
      {children}
    </a>
  </div>

const Hero: FunctionComponent = () =>
  <div className="homeSplashFade">
    <div className="wrapper">
      <div className="leftHero">
        <h2 className="projectTitle">
          Estimate project velocity fast for
          <span> effective milestone and release planning</span>
        </h2>
        <div className="buttonSection">
          <Button className=" orangeButton" href='/connect' target="_self">
            Connect to Pivotal Tracker
            <span>
              <img className='rightChevron' src='/white-right-arrow.svg' alt='Right arrow'/>
            </span>
          </Button>
          <Button className="splashButton orangeButton" href='/upload' target="_self">
            Upload a spreadsheet
            <span>
              <img className='rightChevron' src='/white-right-arrow.svg' alt='Right arrow'/>
            </span>
          </Button>
        </div>
      </div>
      <div className="heroImage">
        <img src='/placeholder_img.png'/>
      </div>
    </div>
    <img className="downCheveron" src="/white-down-arrow.svg"  alt='Arrow pointing down'></img>
  </div>

const KeyBenefits: FunctionComponent = () =>
  <div className='whiteBackground'>
    <div className="wrapper">
      <div className="keyBenefits">
        <div className="benefit">
          <img src='/placeholder_img.png' alt="" className="benefitImage" />
          <h3>Quick setup</h3>
          <p>Upload user stories or import straight from Pivotal Tracker</p>
        </div>
        <div className="benefit">
          <img src='/placeholder_img.png' alt="" className="benefitImage" />
          <h3>Build consensus</h3>
          <p>?</p>
        </div>
        <div className="benefit">
          <img src='/placeholder_img.png' alt="" className="benefitImage" />
          <h3>Plan effectively</h3>
          <p>Get clarity on milestone and release planning</p>
        </div>
      </div>
    </div>
  </div>

const KeyBenefitDescription: FunctionComponent = () =>
  <div className='lightBlueBackground'>
    <div className='wrapper'>
      <div className='section'>
        <div className='sectionElement'>
          <h3>Key benefit description</h3>
          <p>...</p>
        </div>
        <div className='sectionElement featureImage'>
          <img className='screenshot' src='/screencapture-velocitaire-estimation.png' alt='Velocitaire estimates the time required to finish the project'/>
        </div>
      </div>
    </div>
  </div>

const GetStarted: FunctionComponent = () =>
  <div className='whiteBackground'>
    <div className='wrapper'>
      <div className='section'>
        <div className='sectionElement featureImage'>
          <img className='screenshot' src='/screencapture-speadsheet.png' alt='Spreadsheet for user stories'/>
        </div>
        <div className='sectionElement'>
          <h3>What do you need to get started?</h3>
          <p>To play the game you need your project broken down into user stories and a story point estimate for each story. You can upload your user stories or import them straight from Pivotal Tracker.</p>
        </div>
      </div>
    </div>
  </div>

const BlogPosts: FunctionComponent = () =>
  <div className='lightBlueBackground'>
    <div className='wrapper'>
      <div className='section'>
        <div className='sectionElement blogPostsDescription'>
          <h3>Blog posts</h3>
          <p>The team behind Velocitaire regularly publish blog posts about how to work more effectively and have also open sourced their handbook.</p>
          <Button className="orangeButton" href="https://blog.pixielabs.io/" target="_self">
            Read more
            <span>
              <img className='rightChevron' src='/white-right-arrow.svg' alt='Right arrow'/>
            </span>
          </Button>
        </div>
        <div className='sectionElement featureImage imageGroup'>
          <img className='blogPageScreenshot' src='/screencapture-blog-posts-overlook.png' alt='Pixie labs blog page'/>
          <img className='blogScreenshot' src='/screencapture-blog-post-open.png' alt='A blog post about Velocitaire'/>
        </div>
      </div>
    </div>
  </div>

const PixieLabs: FunctionComponent = () =>
  <div className='pixieContainer whiteBackground'>
    <div className='pixieWrapper wrapper'>
      <div className='pixieBackground'>
        <img src='/office.jpg' alt='Pixie labs team in an office'/>
      </div>
      <div className='square squareSmall squareLeftRed'></div>
      <div className='square squareLarge squareLeftGreen'></div>
      <div className='square squareLarge squareRightRed'></div>
      <div className='square squareSmall squareRightGreen'></div>
      <div className='pixieBlock'>
        <img src='/pixielabs-logo-white.svg' alt='Pixie Labs logo'/>
        <h3 className='pixieLabsTitle'>
          Have an idea for <span className='redUnderline'>working smarter</span> or
          a <span className='greenUnderline'>unique customer experience?</span>
        </h3>
        <Button className="whiteButton" href="https://pixielabs.io" target="_self">
          Let's have a chat
          <span>
            <img className='rightChevron' src='/black-right-arrow.svg' alt='Right arrow'/>
          </span>
        </Button>
      </div>
    </div>
  </div>

const Home: FunctionComponent = () =>
  <>
    <Hero />
    <KeyBenefits />
    <KeyBenefitDescription />
    <GetStarted />
    <BlogPosts />
    <PixieLabs />
  </>
export default Home;
