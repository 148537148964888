import React, { Component, ChangeEvent } from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect, Link
} from 'react-router-dom';

// Styles
import './styles/App.css';
import './styles/Card.css';
import './styles/Indicator.css';
import './styles/Home.css';

// Pages
import Home from './Home';
import PivotalSetup from './PivotalSetup';
import SpreadSheetSetup from './SpreadSheetSetup';
import GamePlay from './GamePlay';

// Helpers
import { track } from './lib/ga';
import { calculateRoundVelocity, calculateTotalStoryPoints } from './lib/velocityHelpers';

// Typescript things
import { Story } from './StoryInterface';
import { Round } from './RoundClass';

// Constants
const MAX_REJECTIONS = 3;
const storiesKey = 'allStories';
const iterationKey = 'iterations';

class State {
  allStories: Story[] = [];
  availableStories: Story[] = [];
  acceptedStories: Story[] = [];
  currentStory: Story | null = null;
  rejections: number = 0;
  rounds: Round[] = [];
  gameOver: boolean = false;
  // Could this be something other than a string?
  iterationLength: number = 0;
  totalStoryPoints: number = 0;
}

//  Main App Component. It is responsible for managing state.
class App extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = new State();
  }

  // Hydrate stories as early on as possible in component lifecycle.
  componentDidMount() {
    // Check whether there are any stories in sessionStorage.
    if (sessionStorage.hasOwnProperty(storiesKey)) {
      let stories = sessionStorage.getItem(storiesKey);
      // If there are any, hydrate state.
      if (stories) {
        track('Hydrated stories', undefined, undefined, undefined, true);
        const allStories = JSON.parse(stories);
        const currentStory = this.random(allStories);
        this.setState({
          allStories,
          currentStory,
          availableStories: [...allStories]
        });
      }
    }
    if (sessionStorage.hasOwnProperty(iterationKey)) {
      let iteration = sessionStorage.getItem(iterationKey);
      if (iteration) {this.setState({iterationLength: JSON.parse(iteration)})};
    }
  }

  // Returns true if there are stories and iteration length i.e. we can play!
  validGame(): boolean {    
    return this.state.allStories && !!this.state.iterationLength;
  }

  // Sets the iteration length string in state.
  setIteration(event: ChangeEvent<HTMLInputElement>): void {
    const iterationLength = Number(event.target.value);
    sessionStorage.setItem(iterationKey, JSON.stringify(iterationLength));
    this.setState({ iterationLength });
  }

  // Sets stories in state.
  setStories(stories: Story[]): void {
    sessionStorage.setItem(storiesKey, JSON.stringify(stories));
    this.setState({
      allStories: stories,
      availableStories: [...stories],
      currentStory: this.random(stories),
      totalStoryPoints: calculateTotalStoryPoints([...stories])
    });
  }

  // Returns a random story from an array of stories, just a helper function.
  random(stories: Story[]): Story {
    return stories[Math.floor(Math.random() * stories.length)]
  }

  // Adds the current story to accepted stories, and moves onto next.
  acceptStory(): void {
    const { currentStory, acceptedStories } = this.state;
    if (currentStory) { acceptedStories.push(currentStory)}
    track('Accepted story');
    this.next();
  }

  // Rejects the current story, and moves onto next. Unless we've reached the
  // max number of rejections for this round! Then we finish the round.
  rejectStory() {
    const { rejections } = this.state;
    track('Rejected story');
    if (rejections === MAX_REJECTIONS - 1) {
      this.finishRound();
    } else {
      this.setState({rejections: rejections + 1});
      this.next();
    }
  }

  // Removes the current story from available stories, finishes the round if
  // there are none left, or chooses the next random story.
  next(): void {
    const {availableStories, currentStory} = this.state;
    // Remove the current story from available stories
    if (currentStory) {
      const storyIndex = availableStories.indexOf(currentStory);
      if (storyIndex > -1) { availableStories.splice(storyIndex, 1) }
    }
    if (availableStories.length === 0) { return this.finishRound(); }
    this.setState({ currentStory: this.random(availableStories) });
  }

  // Saves everything to do with this round (stories and velocity), and moves
  // onto the next round. Unless we're out of rounds! Then we end the game.
  finishRound(): void {
    const { acceptedStories, rounds, allStories } = this.state;
    const currentRound = new Round();
    currentRound.stories = acceptedStories;
    currentRound.averageVelocity = calculateRoundVelocity(acceptedStories);
    rounds.push(currentRound);

    if (rounds.length === 5) {
      track('Finished estimating');
      // Stop the game after 5 rounds.
      this.setState({ gameOver: true });
    } else {
      track('Finished round', undefined, undefined, rounds.length.toString());
    }

    this.setState({
      acceptedStories: [],
      // Clone allStories to availableStories
      availableStories: [...allStories],
      rejections: 0,
      currentStory: this.random(allStories)
    });
  }

  // Play again, but with the same stories.
  resetGame(): void {
    const { allStories } = this.state;
    this.setState({
      acceptedStories: [],
      // Clone allStories to availableStories
      availableStories: [...allStories],
      rejections: 0,
      currentStory: this.random(allStories),
      gameOver: false,
      rounds: []
    });
    track('Played game again with same stories');
  }

  // Play again, but with new stories.
  playAgain(finished: boolean): void {
    track(finished ? 'Play again' : 'Exited game early');
    sessionStorage.removeItem(storiesKey);
    sessionStorage.removeItem(iterationKey);
    this.setState(new State());
  }

  render(): JSX.Element {
    const {
      rounds, acceptedStories, currentStory, availableStories, iterationLength,
      totalStoryPoints
    } = this.state;

    return (
      <Router>
        <header className="nav-background">
          <div className="nav-wrapper">
            <Link className='logo' to="/">
              <h1>Velocitaire</h1>
              <img src="/small-logo-new.png" className="smallPixieLogo" alt="Pixie labs logo" />
            </Link>
          </div>
        </header>
        <main>
          <Switch>
            <Route exact path='/'>
              {this.validGame() ? <Redirect to="/play" /> : <Home/>}
            </Route>
            <Route path="/connect">
              <PivotalSetup
                setStories={this.setStories.bind(this)}
                iterationLength={iterationLength}
                handleInput={this.setIteration.bind(this)}
              />
            </Route>
            <Route path="/upload">
              <SpreadSheetSetup
                availableStories={availableStories}
                iterationLength={iterationLength}
                handleInput={this.setIteration.bind(this)}
                setStories={this.setStories.bind(this)}
              />
            </Route>
            <Route path="/play">
            { this.validGame() ?
              (
                <GamePlay
                iterationLength={iterationLength}
                rounds={rounds}
                acceptStory={this.acceptStory.bind(this)} 
                rejectStory={this.rejectStory.bind(this)} 
                finishRound={this.finishRound.bind(this)} 
                playAgain={this.playAgain.bind(this)} 
                currentStory={currentStory}
                acceptedStories={acceptedStories}
                resetGame={this.resetGame.bind(this)}
                gameOver={this.state.gameOver}
                totalStoryPoints={totalStoryPoints}
                />
              ) : <Home/>
            }
            </Route>
          </Switch>
        </main>
        <footer className="footer-background">
          <div className="footer-wrapper">
            <a href="https://pixielabs.io">
              <img className='builtWithLove' src='/built-with-love.png' alt="Pixie Labs" />
            </a>
            <div className="products-wrapper">
              <h5>Our Products</h5>
              <a href="https://cavy.app">Cavy</a>
              <a href="/">Velocitaire</a>
              <a href="https://poker.velocitaire.com">Planning Poker</a>
            </div>
          </div>
        </footer>
      </Router>
    )
  }
}

export default App;
